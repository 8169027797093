// Main designer constants
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const TRIGGER_PROPERTY_EDITOR = 'TRIGGER_PROPERTY_EDITOR'
export const CLOSE_LEFT_PANEL = 'CLOSE_LEFT_PANEL'
export const SET_RIGHT_PANEL_STATE = 'SET_RIGHT_PANEL_STATE'
export const SET_ACTIVE_EDIT_MODE = 'SET_ACTIVE_EDIT_MODE'
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE'
export const SET_PREVIEW_MODE_DEVICE = 'SET_PREVIEW_MODE_DEVICE'
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW'
export const PUSH_NAVIGATION = 'PUSH_NAVIGATION'
export const POP_NAVIGATION = 'POP_NAVIGATION'
export const START_EDITOR = 'START_EDITOR'
export const ASK_TO_SAVE = 'ASK_TO_SAVE'
export const SET_MAIN_DESIGNER_PROPERTY = 'SET_MAIN_DESIGNER_PROPERTY'
export const SET_TEMPLATE_SELECTOR_OPEN = 'SET_TEMPLATE_SELECTOR_OPEN'
export const OPEN_SITE_SETTINGS = 'OPEN_SITE_SETTINGS'
export const SET_ACTIVE_PAGE_URI = 'SET_ACTIVE_PAGE_URI'
export const SET_PUBLISH_METHOD = 'SET_PUBLISH_METHOD'
export const SET_SIDEBAR_TITLE = 'SET_SIDEBAR_TITLE'
export const UPDATE_SIDEBAR_HEADER = 'UPDATE_SIDEBAR_HEADER'
export const SET_LOCKED_SIDEBAR = 'SET_LOCKED_SIDEBAR'
export const SET_SIDEBAR_ACTIVE = 'SET_SIDEBAR_ACTIVE'
export const GET_SITE_BY_ID = 'GET_SITE_BY_ID'
export const GET_SITE_BY_ID_FULFILLED = 'GET_SITE_BY_ID_FULFILLED'
export const SET_ROUTE_INFO = 'SET_ROUTE_INFO'
export const MOUNTED_DESIGNER = 'MOUNTED_DESIGNER'
export const INCREMENT_STEP = 'INCREMENT_STEP'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
// export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS'
// export const FETCH_CAMPAIGNS_FULFILLED = 'FETCH_CAMPAIGNS_FULFILLED'
export const SET_SITE_ID_FOR_CAMPAIGN = 'SET_SITE_ID_FOR_CAMPAIGN'
export const GET_APP_MARKET_ID = 'GET_APP_MARKET_ID'

// SEO constants
export const GENERATE_SEO_KEYWORDS = 'GENERATE_SEO_KEYWORDS'
export const GENERATE_GLOBAL_SEO_KEYWORDS = 'GENERATE_GLOBAL_SEO_KEYWORDS'

// API constants
export const GET_ONBOARDING_INPUT = 'GET_ONBOARDING_INPUT'
export const GET_ONBOARDING_INPUT_FULFILLED = 'GET_ONBOARDING_INPUT_FULFILLED'
export const SET_LAYOUTS = 'SET_LAYOUTS'
export const GET_LAYOUTS = 'GET_LAYOUTS'
export const GET_LAYOUTS_FULFILLED = 'GET_LAYOUTS_FULFILLED'
export const GET_SITE = 'GET_SITE'
export const GET_SITE_FOR_CAMPAIGN = 'GET_SITE_FOR_CAMPAIGN'
export const SET_LAST_SAVED = 'SET_LAST_SAVED'

export const SET_ADMIN_DEMO_DATA = 'SET_ADMIN_DEMO_DATA'

export const GET_DOMAIN_INFO_BY_SITENAME = 'GET_DOMAIN_INFO_BY_SITENAME'
export const GET_DOMAIN_INFO_BY_SITENAME_ERROR = 'GET_DOMAIN_INFO_BY_SITENAME_ERROR'
export const GET_DOMAIN_INFO_BY_SITENAME_PENDING = 'GET_DOMAIN_INFO_BY_SITENAME_PENDING'
export const GET_DOMAIN_INFO_BY_SITENAME_FULFILLED = 'GET_DOMAIN_INFO_BY_SITENAME_FULFILLED'

export const GET_SITE_DOMAIN = 'GET_SITE_DOMAIN'
export const GET_SITE_DOMAIN_ERROR = 'GET_SITE_DOMAIN_ERROR'
export const GET_SITE_DOMAIN_PENDING = 'GET_SITE_DOMAIN_PENDING'
export const GET_SITE_DOMAIN_FULFILLED = 'GET_SITE_DOMAIN_FULFILLED'
export const GET_SITE_FULFILLED = 'GET_SITE_FULFILLED'
export const GET_SITE_ERROR = 'GET_SITE_ERROR'
export const GET_SITE_PENDING = 'GET_SITE_PENDING'
export const POST_SITE = 'POST_SITE'
export const POST_SITE_PENDING = 'POST_SITE_PENDING'
export const AUTO_SAVE_SITE = 'AUTO_SAVE_SITE'
export const UPDATE_SITE_NAME = 'UPDATE_SITE_NAME'
export const POST_UPDATE_SITE_NAME = 'POST_UPDATE_SITE_NAME'
export const OPEN_UPGRADE_MODAL = 'OPEN_UPGRADE_MODAL'
export const CLOSE_UPGRADE_MODAL = 'CLOSE_UPGRADE_MODAL'

export const GET_SITES = 'GET_SITES'
export const GET_TEMPLATE = 'GET_TEMPLATE'
export const GET_TEMPLATE_FULFILLED = 'GET_TEMPLATE_FULFILLED'
// export const REUSE_TEMPLATE = 'REUSE_TEMPLATE'
// export const REUSE_TEMPLATE_FULFILLED = 'REUSE_TEMPLATE_FULFILLED'
// export const BUILD_TEMPLATE = 'BUILD_TEMPLATE'
// export const BUILD_TEMPLATE_FULFILLED = 'BUILD_TEMPLATE_FULFILLED'
export const GET_SITE_METADATA = 'GET_SITE_METADATA'
export const PREVIEW_SITE = 'PREVIEW_SITE'
export const POST_PUBLISH = 'POST_PUBLISH'
export const POST_PUBLISH_PENDING = 'POST_PUBLISH_PENDING'
export const POST_PUBLISH_ERROR = 'POST_PUBLISH_ERROR'
export const POST_PUBLISH_FULFILLED = 'POST_PUBLISH_FULFILLED'
export const GET_ONBOARDING_TEMPLATE = 'GET_ONBOARDING_TEMPLATE'
export const GET_ONBOARDING = 'GET_ONBOARDING'
export const GET_ONBOARDING_FULFILLED = 'GET_ONBOARDING_FULFILLED'

export const CLEAR_API_ONBOARDING = 'CLEAR_API_ONBOARDING'

export const GET_DEMODATA = 'GET_DEMODATA'
export const GET_DEMODATA_PENDING = 'GET_DEMODATA_PENDING'
export const GET_DEMODATA_RESET = 'GET_DEMODATA_RESET'
export const GET_DEMODATA_FULFILLED = 'GET_DEMODATA_FULFILLED'

export const DO_APPMARKET_CALL = 'DO_APPMARKET_CALL'

export const GET_LOGOS_ACCOUNT = 'GET_LOGOS_ACCOUNT'

export const POST_SITE_INFO_LOGO = 'POST_SITE_INFO_LOGO'
export const POST_SITE_INFO_LOGO_FULFILLED = 'POST_SITE_INFO_LOGO_FULFILLED'
export const POST_SITE_INFO_LOGO_PENDING = 'POST_SITE_INFO_LOGO_PENDING'
export const POST_SITE_INFO_LOGO_ERROR = 'POST_SITE_INFO_LOGO_ERROR'

export const SAVE_ADMIN_DEMODATA = 'SAVE_ADMIN_DEMODATA'
export const SET_ADMIN_PARAMS = 'SET_ADMIN_PARAMS'

export const GET_AVAILABLE_DOMAINS = 'GET_AVAILABLE_DOMAINS'
export const GET_AVAILABLE_DOMAINS_FULFILLED = 'GET_AVAILABLE_DOMAINS_FULFILLED'

export const GET_CREATE_STORE = 'GET_CREATE_STORE'
export const RESET_PUBLISH_STORE = 'RESET_PUBLISH_STORE'

export const SET_MARKETING_CALLBACK = 'SET_MARKETING_CALLBACK'
export const RETRIEVE_CATEGORIES = 'RETRIEVE_CATEGORIES'

export const SET_PENDING_CHANGES_IN_EDITOR = 'SET_PENDING_CHANGES_IN_EDITOR'

// Font editor constants
export const TRIGGER_FONT_EDITING_MODE = 'TRIGGER_FONT_EDITING_MODE'

// Image picker
export const SET_IMAGE_PICKER_VISIBILITY = 'SET_IMAGE_PICKER_VISIBILITY'
export const SET_IMAGE_PICKER_VIEW = 'SET_IMAGE_PICKER_VIEW'
export const UPDATE_EDITOR_STATE = 'UPDATE_EDITOR_STATE'

// Popovers
export const REGISTER_POPOVER = 'REGISTER_POPOVER'
export const SET_POPOVER_ACTIVE_STATE = 'SET_POPOVER_ACTIVE_STATE'
export const DEACTIVATE_POPOVERS = 'DEACTIVATE_POPOVERS'
export const SET_POPOVER = 'SET_POPOVER'
export const UPDATE_POPOVER = 'UPDATE_POPOVER'
export const CLOSE_POPOVER = 'CLOSE_POPOVER'
export const UNREGISTER_POPOVER = 'UNREGISTER_POPOVER'
export const UPDATE_POPOVER_WINDOW = 'UPDATE_POPOVER_WINDOW'
export const SET_POPOVER_WINDOW_ORIENTATION = 'SET_POPOVER_WINDOW_ORIENTATION'

export const SET_MODAL = 'SET_MODAL'

// Set image url from imagePicker to Popover reducer
export const SET_IMAGE_URL = 'SET_IMAGE_URL'

// NPSscore
export const HAS_NPS = 'HAS_NPS'
export const HAS_NPS_FULFILLED = 'HAS_NPS_FULFILLED'
export const HAS_NPS_PENDING = 'HAS_NPS_PENDING'
export const HAS_NPS_ERROR = 'HAS_NPS_ERROR'

export const ADD_NPS = 'ADD_NPS'
export const ADD_NPS_FULFILLED = 'ADD_NPS_FULFILLED'
export const ADD_NPS_PENDING = 'ADD_NPS_PENDING'
export const ADD_NPS_ERROR = 'ADD_NPS_ERROR'

// Theme editor
export const SET_THEME_EDITOR_ENABLED = 'SET_THEME_EDITOR_ENABLED'
export const SET_THEME_EDITOR_VIEW = 'SET_THEME_EDITOR_VIEW'
export const SET_PAGE_OVERVIEW = 'SET_PAGE_OVERVIEW'

export const RETRIEVE_COLOR_THEME_BY_TEMPLATE_NAME = 'RETRIEVE_COLOR_THEME_BY_TEMPLATE_NAME'

// Context
export const ACTIVATE_POPOVER_WINDOW = 'ACTIVATE_POPOVER_WINDOW'
export const DEACTIVATE_POPOVER_WINDOW = 'DEACTIVATE_POPOVER_WINDOW'
export const REGISTER_POPOVER_WINDOW = 'REGISTER_POPOVER_WINDOW'

// Edit
export const EDIT_CHANGE_PROPS = 'EDIT_CHANGE_PROPS'
export const EDIT_UNDO = 'EDIT_UNDO'
export const EDIT_REDO = 'EDIT_REDO'
export const SHOW_VERSION_HISTORY = 'SHOW_VERSION_HISTORY'
export const SELECT_VERSION_HISTORY = 'SELECT_VERSION_HISTORY'
export const EDIT_SET_RESTORE_POINT = 'EDIT_SET_RESTORE_POINT'
export const EDIT_GOTO_RESTORE_POINT = 'EDIT_GOTO_RESTORE_POINT'
export const EDIT_UPDATE_UNDO_STATES = 'EDIT_UPDATE_UNDO_STATES'
export const EDIT_COMMIT_UNDO_STATE = 'EDIT_COMMIT_UNDO_STATE'
export const EDIT_CLEAR_UNDO_STATE = 'EDIT_CLEAR_UNDO_STATE'
export const EDIT_INITIAL_LOAD = 'EDIT_INITIAL_LOAD'
export const EDIT_LOADED_TREE = 'EDIT_LOADED_TREE'
export const SET_ACTIVE_SECTION_BY_TREE_ID = 'SET_ACTIVE_SECTION_BY_TREE_ID'
export const NO_CHANGES = 'NO_CHANGES'

// from editor
export const UPDATE_PROPERTY_FROM_EDITOR = 'UPDATE_PROPERTY_FROM_EDITOR'
export const UPDATE_ARRAY_ORDER_FROM_EDITOR = 'UPDATE_ARRAY_ORDER_FROM_EDITOR'
export const REMOVE_ARRAY_ITEM_FROM_EDITOR = 'REMOVE_ARRAY_ITEM_FROM_EDITOR'
export const MOVE_ITEM_FROM_EDITOR = 'MOVE_ITEM_FROM_EDITOR'
export const EDIT_SET_TREE_IDS = 'EDIT_SET_TREE_IDS'
export const ADD_ITEM_FROM_EDITOR = 'ADD_ITEM_FROM_EDITOR'

export const RESET_ENTIRE_STORE = 'RESET_ONBOARDING'

// Guided Tour
export const GUIDED_TOUR_REGISTER_WINDOW = 'GUIDED_TOUR_REGISTER_WINDOW'
export const GUIDED_TOUR_ACTIVATE_NAMESPACE = 'GUIDED_TOUR_ACTIVATE_NAMESPACE'
export const GUIDED_TOUR_DEACTIVATE_NAMESPACE = 'GUIDED_TOUR_DEACTIVATE_NAMESPACE'
export const GUIDED_TOUR_SET_STEP_ACTIVE = 'GUIDED_TOUR_SET_STEP_ACTIVE'
export const GUIDED_TOUR_SET_STEP_POSITION = 'GUIDED_TOUR_SET_STEP_POSITION'
export const GUIDED_TOUR_SET_FORCIBLY_HIDE_STEPS = 'GUIDED_TOUR_SET_FORCIBLY_HIDE_STEPS'
export const GUIDED_TOUR_SET_SKIP_TOUR = 'GUIDED_TOUR_SET_SKIP_TOUR'
export const GUIDED_TOUR_REGISTER_WINDOWS = 'GUIDED_TOUR_REGISTER_WINDOWS'
export const GUIDED_TOUR_DEREGISTER_ALL_WINDOWS = 'GUIDED_TOUR_DEREGISTER_ALL_WINDOWS'

export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const GET_USER_SETTINGS_PENDING = 'GET_USER_SETTINGS_PENDING'
export const GET_USER_SETTINGS_FULFILLED = 'GET_USER_SETTINGS_FULFILLED'
export const GET_USER_SETTINGS_ERROR = 'GET_USER_SETTINGS_ERROR'

export const SET_USER_SETTING = 'SET_USER_SETTING'
export const SET_USER_SETTING_PENDING = 'SET_USER_SETTING_PENDING'
export const SET_USER_SETTING_FULFILLED = 'SET_USER_SETTING_FULFILLED'
export const SET_USER_SETTING_ERROR = 'SET_USER_SETTING_ERROR'

export const DELETE_USER_SETTING = 'DELETE_USER_SETTING'

// Appmarket
export const RENDER_DASHBOARD = 'RENDER_DASHBOARD'
export const RENDER_DASHBOARD_PENDING = 'RENDER_DASHBOARD_PENDING'
export const RENDER_DASHBOARD_FULFILLED = 'RENDER_DASHBOARD_FULFILLED'

export const SET_DEVELOPER_MODE_ACTIVE = 'SET_DEVELOPER_MODE_ACTIVE'

export const GET_APPS_BY_ACCOUNT = 'GET_APPS_BY_ACCOUNT'
export const GET_APPS_BY_ACCOUNT_PENDING = 'GET_APPS_BY_ACCOUNT_PENDING'
export const GET_APPS_BY_ACCOUNT_ERROR = 'GET_APPS_BY_ACCOUNT_ERROR'
export const GET_APPS_BY_ACCOUNT_FULFILLED = 'GET_APPS_BY_ACCOUNT_FULFILLED'

export const GET_APPS_BY_SITE_ID = 'GET_APPS_BY_SITE_ID'
export const GET_APPS_BY_SITE_ID_PENDING = 'GET_APPS_BY_SITE_ID_PENDING'
export const GET_APPS_BY_SITE_ID_ERROR = 'GET_APPS_BY_SITE_ID_ERROR'
export const GET_APPS_BY_SITE_ID_FULFILLED = 'GET_APPS_BY_SITE_ID_FULFILLED'

export const AT_SET_ACCOUNT_TYPE = 'AT_SET_ACCOUNT_TYPE'
export const AT_SET_AUTHENTICATION_METHOD = 'AT_SET_AUTHENTICATION_METHOD'
export const SET_BOUGHT_APPS = 'SET_BOUGHT_APPS'
export const LOADED_HEADER_IMAGE = 'LOADED_HEADER_IMAGE'

export const MAIL_SETTINGS_FORM = 'MAIL_SETTINGS_FORM'
export const MAIL_SETTINGS_FORM_PENDING = 'MAIL_SETTINGS_FORM_PENDING'
export const MAIL_SETTINGS_FORM_ERROR = 'MAIL_SETTINGS_FORM_ERROR'
export const MAIL_SETTINGS_FORM_FULFILLED = 'MAIL_SETTINGS_FORM_FULFILLED'

export const SAVE_MAIL_SETTINGS = 'SAVE_MAIL_SETITINGS'
export const SAVE_MAIL_SETTINGS_PENDING = 'SAVE_MAIL_SETITINGS_PENDING'
export const SAVE_MAIL_SETTINGS_ERROR = 'SAVE_MAIL_SETITINGS_ERROR'
export const SAVE_MAIL_SETTINGS_FULFILLED = 'SAVE_MAIL_SETITINGS_FULFILLED'

export const CREATE_LANDING_PAGE = 'CREATE_LANDING_PAGE'
export const PUT_CAMPAIGN_FULFILLED = 'PUT_CAMPAIGN_FULFILLED'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_FULFILLED = 'GET_CAMPAIGN_FULFILLED'
