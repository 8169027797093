import * as constants from './constants'

import { fetchAsJson } from '@eig-builder/core-utils/helpers/fetch-helper'
// import { getUrl, siteNames } from '@eig-builder/core-utils/helpers/url-helper'
import Fetch from 'shared/fetch-wrapper'
import { isHostedBrand } from 'shared/account-type-helper'

import { reApiUrl, billingApiUrl } from 'shared/api-urls'

export const getBrandConfiguration = () => {
  const options = {
    key: constants.GET_BRAND_CONFIGURATION,
    convertToCamelCase: true
  }

  return fetchAsJson(reApiUrl('token/config'), options)
}

export const getLimitations = (siteId = 0, callback) => {
  return (dispatch, getState) => {
    const dispatchAction = jwt => {
      const partnerId = (getState().auth.getTokenValidateResponse && getState().auth.getTokenValidateResponse.partnerId) || getState().configuration.partnerId || window.runtimeConfig._partnerId

      if (!partnerId) {
        return
      }

      // TODO fix this better
      const options = {
        key: constants.GET_BRAND_LIMITATIONS,
        convertToCamelCase: isHostedBrand()
      }

      if (jwt) {
        options.fetchOptions = {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      }
      const limitationOptions = {
        product: 'expresseditor',
        partnerId: partnerId
      }
      if (siteId) {
        limitationOptions.siteId = siteId
      }
      dispatch(
        fetchAsJson(
          billingApiUrl('limitation', limitationOptions),
          options
        )
      ).then(() => {
        if (callback) {
          callback()
        }
      })
    }
    if (isHostedBrand()) {
      Fetch.postAsJson(reApiUrl('token/billing'), {}).then(response => {
        if (response.jwt) {
          dispatchAction(response.jwt)
        }
      })
    } else {
      dispatchAction()
    }
  }
}
