// POPOVER
export const SHOW_POPOVER = 'SHOW_POPOVER'
export const UPDATE_POPOVER_VISIBILITY = 'UPDATE_POPOVER_VISIBILITY'
export const UPDATE_POPOVER_POSITION = 'UPDATE_POPOVER_POSITION'
export const UPDATE_POPOVER_TYPE = 'UPDATE_POPOVER_TYPE'
export const POPOVER_ACTIVATED = 'POPOVER_ACTIVATED'
export const POPOVER_DEACTIVATED = 'POPOVER_DEACTIVATED'
export const POPOVERS_DEACTIVATED = 'POPOVERS_DEACTIVATED'
export const SET_SIDEBAR_VIEW = 'SET_SIDEBAR_VIEW'
export const CLOSE_POPOVER = 'CLOSE_POPOVER'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CLOSE_ALL_POPOVERS = 'CLOSE_ALL_POPOVERS'
export const SET_POPOVER_RECT = 'SET_POPOVER_RECT'

// MODAL
export const SET_MODAL = 'SET_MODAL'
export const MODAL_ACTIVATED = 'MODAL_ACTIVATED'
export const MODAL_DEACTIVATED = 'MODAL_DEACTIVATED'
export const SHOW_PAGE_MANAGER = 'SHOW_PAGE_MANAGER'

// SECTION
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT'
export const CHANGE_LAYOUT_DEVKIT = 'CHANGE_LAYOUT_DEVKIT'
export const LAYOUT_COUNT = 'LAYOUT_COUNT'
export const SECTION_PANEL_TOGGLE = 'SECTION_PANEL_TOGGLE'
export const CLOSE_LAYOUT = 'CLOSE_LAYOUT'
export const CONFIG_CHANGED = 'CONFIG_CHANGED'
export const SEND_ADD_LAYOUT_ANALYTICS_EVENT = 'SEND_ADD_LAYOUT_ANALYTICS_EVENT'

// GLOBAL
export const SITE_LOADED = 'SITE_LOADED'
export const UPDATE_WINDOW_HEIGHT = 'UPDATE_WINDOW_HEIGHT'
export const SHOW_ERROR = 'SHOW_ERROR'
// export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW'
// export const SET_ZOOM = 'SET_ZOOM'
export const OPEN_SITE_SETTINGS = 'OPEN_SITE_SETTINGS'

export const SCROLL_TO = 'SCROLL_TO'
export const SCROLL_ENABLED = 'SCROLL_ENABLED'

export const CKEDITOR_LINK = 'CKEDITOR_LINK'
export const UPDATE_TREE = 'UPDATE_TREE'

// PREVIEW
export const SET_PUBLISHED_SITE_BODY = 'SET_PUBLISHED_SITE_BODY'
export const RESIZE_POPOVER = 'RESIZE_POPOVER'
export const PUBLISH_MAIL_FOR_PREVIEW = 'PUBLISH_MAIL_FOR_PREVIEW'

// PAGES
export const SET_PAGE_OVERVIEW = 'SET_PAGE_OVERVIEW'
export const SELECT_PAGE = 'SELECT_PAGE'
export const SET_THEME_EDITOR_VIEW = 'SET_THEME_EDITOR_VIEW'

// LIVE BUILD
export const CONTINUE_FROM_LIVE_BUILD_TO_EDITOR = 'CONTINUE_FROM_LIVE_BUILD_TO_EDITOR'

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM'

export const INSERT_NEW_SECTION = 'INSERT_NEW_SECTION'
