import { siteNames, getUrl, getHelpUrl, api, getDataProxyUrl } from '@eig-builder/core-utils/helpers/url-helper'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'

import { isHostedBrand } from 'shared/account-type-helper'

import Communication from './communication'
import * as events from 'shared/events'

let configuration = {}

class ConfigurationManager {
  static setConfiguration (newConfiguration) {
    configuration = newConfiguration

    if (configuration.displayName) {
      document.title = configuration.displayName
    }

    Communication.send(events.CONFIG_CHANGED, true)
  }

  static replaceDomainPlaceholders (domain) {
    let hasChanges = false
    Object.keys(configuration).forEach(key => {
      if (configuration[key] && configuration[key].includes && configuration[key].includes('%domain%')) {
        configuration[key] = configuration[key].replace('%domain%', domain)
        hasChanges = true
      }
    })

    if (hasChanges) {
      Communication.send(events.CONFIG_CHANGED, true)
    }
  }

  static changeFavicon (src) {
    const head = document.head || document.getElementsByTagName('head')[0]

    const link = document.createElement('link')
    const oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'shortcut icon'
    link.href = src
    if (oldLink) {
      head.removeChild(oldLink)
    }
    head.appendChild(link)
  }

  static getLogoutUrl () {
    return configuration.logout || getUrl(siteNames.LOGIN, '/logout')
  }

  static getLoginUrl () {
    return configuration.login || getUrl(siteNames.LOGIN, '')
  }

  static setApiKeysFromConfig (apiKeys) {
    const mapApiKey = configuration.googleApiKey
    if (mapApiKey) {
      apiKeys.googleMapsApiKey = mapApiKey
    }
  }

  static setUrlsForPublish (urls) {
    urls[siteNames.RE_API] = api('').slice(0, -1)
    urls[siteNames.HOSTING_API] = getUrl(siteNames.HOSTING_API)
    urls.dataproxy = getDataProxyUrl()
  }

  static getHelpUrl () {
    return configuration.helpCenter || getHelpUrl()
  }

  static getManageSitesUrl () {
    return configuration.createNewSite || configuration.cp || getUrl(siteNames.MY, '/sites')
  }

  static getChooseDomainUrl () {
    return configuration.chooseDomain || this.getManageSitesUrl()
  }

  static getUpgradeUrl (siteId) {
    if (configuration.upgradeUrl) {
      if (configuration.upgradeUrl.endsWith('=')) {
        return configuration.upgradeUrl + siteId
      }
      return configuration.upgradeUrl
    }

    return configuration.cp
  }

  static isUpgradeButtonHidden () {
    if (configuration.upgradeUrl) {
      if (configuration.upgradeUrl.toLowerCase().endsWith('hidden')) {
        return true
      }
    }
    return false
  }

  static getHeaderLogo () {
    if (isHostedBrand()) {
      return configuration.logo || configuration.headerLogo || ''
    }
    return getRuntimeConfig()._brandImages.BrandLogoSVG || ''
  }

  static getWhiteLogo () {
    return getRuntimeConfig()._brandImages.BrandLogoWhite || ''
  }

  // static getWhiteLogo () {
  //   return getRuntimeConfig()._brandImages.BrandLogoWhite || ''
  // }

  static getPublicUrl () {
    return configuration.cp || getUrl(siteNames.CP_MY)
  }
}

export default ConfigurationManager
